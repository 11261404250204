import { Skeleton } from 'antd';
import Link from 'next/link';
import { useFeaturesPages } from 'services/hooks/queries/useFeaturesPages';
import { iconMap } from 'services';
import { getAppConfiguration } from '@utils/browserEnv';

const { appCode } = getAppConfiguration();

export const FeaturesMenuItems = () => {
  const { data, isLoading } = useFeaturesPages({ limit: 100, appCode });

  const loadingItems = [0, 1, 2, 3].map((idx) => ({
    key: 'my-menu-ids-' + idx,
    label: <Skeleton avatar={{ size: 'small' }} loading={isLoading} paragraph={{ rows: 1, width: 150 }} />,
  }));

  if (isLoading) {
    return loadingItems;
  }

  const items = data?.featuresPages?.data;
  const menuItems =
    items !== undefined &&
    items.map((item) => ({
      key: item?.attributes?.slug,
      icon: iconMap(item?.attributes?.icon?.name),
      label: <Link href={`/features/${item?.attributes?.slug}`}>{item?.attributes?.title}</Link>,
      style: { minWidth: '220px' },
    }));

  return menuItems;
};
