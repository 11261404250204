import { MenuOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Button } from 'antd';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { SelectApp } from 'sailor-ui/SelectApp';
import styles from 'sailor-ui/styles/Header.module.scss';
import { useScrollHandler } from 'services';
import LeftMenu from './LeftMenu';
import MainDrawer from './MainDrawer';
import RightMenu from './RightMenu';
import UserDrawerNav from './User/UserDrawerNav';

const PageHeader: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const router = useRouter();

  const { lg: responsive } = Grid.useBreakpoint();

  const { stickyHeader } = useScrollHandler({ breakpoint: responsive });
  const [headerTransparent, setHeaderTransparent] = useState(false);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleOnHomeClick = () => {
    router.push('/');
  };

  useEffect(() => {
    const isHome = router.pathname === '/';

    if (isHome) {
      setHeaderTransparent(true);
    }

    const handleScroll = () => {
      if (!isHome) return;

      if (window.scrollY < 60) {
        setHeaderTransparent(true);
      } else {
        setHeaderTransparent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [router?.pathname]);

  return (
    <>
      <header
        className={cn(styles.headerWrapper, {
          [styles.headerTransparent]: headerTransparent,
          [styles.headerScrollDown]: stickyHeader,
          [styles.headerScrollUp]: !stickyHeader,
        })}
      >
        <nav className={styles.navWrapper}>
          <SelectApp currentApp="theboat_landing" onHomeClick={handleOnHomeClick} />

          {!responsive && (
            <Button
              type="text"
              onClick={showDrawer}
              style={{ padding: '6px' }}
              icon={<MenuOutlined style={{ fontSize: 19 }} />}
            />
          )}

          <div className={styles.headerMenu}>
            {responsive && <LeftMenu />}
            {responsive && <RightMenu />}
          </div>
        </nav>
      </header>

      <MainDrawer goBack={false} onClose={onClose} visible={visible}>
        <LeftMenu />
        <RightMenu />
        {isAuthenticated && <UserDrawerNav />}
      </MainDrawer>
    </>
  );
};

export default PageHeader;
