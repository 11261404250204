import {
  BulbOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundOutlined,
  MailOutlined,
  MessageOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import Link from 'next/link';
import { CommunityIcon, ExternalLinkIcon } from 'sailor-ui/CustomIcons';
import { SuitcaseIcon } from '../CustomIcons';
import { getAppConfiguration } from '@utils/browserEnv';

const TARGET_BLANK = { target: '_blank', rel: 'noopener' };

const config = getAppConfiguration();

export const moreMenuItems = (f: (id: string) => string): MenuProps['items'] => [
  {
    key: 'about',
    label: 'About TheBoatApp',
    type: 'group',
    children: [
      {
        key: 'how-it-works',
        icon: <BulbOutlined />,
        label: <Link href="/how-it-works">{f('header.menu.left.how_it_works')}</Link>,
      },
      {
        key: 'our-story',
        icon: <MessageOutlined />,
        label: <Link href="/our-story">{f('header.menu.left.our_story')}</Link>,
      },
      {
        key: 'careers',
        icon: <SolutionOutlined />,
        label: <Link href="/careers">{f('header.menu.left.career')}</Link>,
      },
      {
        key: 'contact-us',
        icon: <MailOutlined />,
        label: (
          <Link href="mailto:Support@MarineDataCloud.com?subject=Support needed for TheBoatApp">
            {f('header.menu.left.contact_us')}
          </Link>
        ),
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'support',
    label: 'Support',
    type: 'group',
    children: [
      {
        key: 'system-status',
        icon: <FundOutlined />,
        label: (
          <Link href="https://status.marinedatacloud.com/" {...TARGET_BLANK}>
            {f('header.menu.left.system_status')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'changelog',
        icon: <ProfileOutlined />,
        label: <Link href="/changelog">{f('header.menu.left.changelog')}</Link>,
      },
      {
        key: 'faq',
        icon: <QuestionCircleOutlined />,
        label: (
          <Link
            href="https://support.marinedatacloud.com/portal/en/kb/theboatapp/frequently-asked-questions-faq"
            {...TARGET_BLANK}
          >
            {f('header.menu.left.faq')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'tutorials',
        icon: <FileTextOutlined />,
        label: (
          <Link href="https://support.marinedatacloud.com/portal/en/kb/theboatapp/tutorials" {...TARGET_BLANK}>
            {f('header.menu.left.tutorials')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'community',
        icon: <CommunityIcon />,
        label: (
          <Link href="https://support.marinedatacloud.com/portal/en/community/theboatapp" {...TARGET_BLANK}>
            {f('header.menu.left.community')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'support',
        icon: <CustomerServiceOutlined />,
        label: (
          <Link href="https://support.marinedatacloud.com" {...TARGET_BLANK}>
            {f('header.menu.left.support')} <ExternalLinkIcon />
          </Link>
        ),
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'legal',
    label: 'Legal',
    type: 'group',
    children: [
      {
        key: 'terms-of-service',
        icon: <SuitcaseIcon />,
        label: (
          <Link href={`${config.mdcLandingUrl}/terms-of-service`} {...TARGET_BLANK}>
            {f('header.menu.left.terms_of_service')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'user-agreement',
        icon: <ProfileOutlined />,
        label: (
          <Link href={`${config.mdcLandingUrl}/user-agreement`} {...TARGET_BLANK}>
            {f('header.menu.left.user_agreement')} <ExternalLinkIcon />
          </Link>
        ),
      },
      {
        key: 'privacy-policy',
        icon: <FileProtectOutlined />,
        label: (
          <Link href={`${config.mdcLandingUrl}/privacy-policy`} {...TARGET_BLANK}>
            {f('header.menu.left.privacy_policy')} <ExternalLinkIcon />
          </Link>
        ),
      },
    ],
  },
];
