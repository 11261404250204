import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Grid, Menu, MenuProps } from 'antd';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from 'sailor-ui/styles/Header.module.scss';
import { blogsMenuItems } from './blogsMenuItems';
import { FeaturesMenuItems } from './featuresMenuItems';
import MainDrawer from './MainDrawer';
import { menuKeys } from './menuKeys';
import MoreMenu from './MoreMenu';
import { moreMenuItems } from './moreMenuItems';

const LeftMenu = () => {
  const { formatMessage } = useIntl();
  const f = useCallback((id: string) => formatMessage({ id }), [formatMessage]);
  const featuresMenus = FeaturesMenuItems();

  const router = useRouter();
  const { useBreakpoint } = Grid;
  const { lg: responsive } = useBreakpoint();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const selectedKeys = useMemo(() => {
    const activeMenuKey = menuKeys.find((item) => item.validPaths.some((path) => path === router.pathname));

    if (!activeMenuKey) {
      const itemStartsWith =
        menuKeys.find((item) => {
          if (item?.startsWith) {
            return router.pathname.startsWith(item?.startsWith);
          }

          return undefined;
        })?.key || '/no-match';

      return itemStartsWith;
    }

    return activeMenuKey?.key || '/no-match';
  }, [router.pathname]);

  const menuItems = useMemo(
    (): MenuProps['items'] => [
      {
        key: 'features',
        label: (
          <Link href="/features">
            <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {f('header.menu.left.features')} <DownOutlined />
            </span>
          </Link>
        ),
        children: featuresMenus as MenuProps['items'],
        popupClassName: styles.subMenuDropdown,
      },
      {
        key: 'checklists',
        label: (
          <Link href="/checklists">
            {f('header.menu.left.checklists')}
            <span className={styles.headerMenuItemBadge}>{f('header.menu.left.checklists.badge')}</span>
          </Link>
        ),
      },
      {
        key: 'pricing',
        label: <Link href="/pricing">{f('header.menu.left.pricing')}</Link>,
      },
      {
        key: 'blogs',
        label: (
          <span>
            {f('header.menu.left.blogs')} <DownOutlined />
          </span>
        ),
        children: blogsMenuItems(f),
        popupClassName: styles.subMenuDropdown,
      },
      responsive
        ? {
            key: 'more',
            label: (
              <span>
                {f('header.menu.left.more')} <DownOutlined />
              </span>
            ),
            children: moreMenuItems(f),
            popupClassName: styles.subMenuDropdown,
          }
        : {
            key: 'more-menu-trigger',
            onClick: showDrawer,
            label: (
              <>
                {f('header.menu.left.more')} <RightOutlined />
              </>
            ),
          },
    ],
    [f, responsive, featuresMenus],
  );

  return (
    <>
      <Menu
        mode={responsive ? 'horizontal' : 'inline'}
        theme={responsive ? 'light' : 'dark'}
        className={cn({ [styles.leftMenuDesktop]: responsive })}
        selectedKeys={[selectedKeys]}
        items={menuItems}
      />

      <MainDrawer onClose={onClose} visible={visible} className={styles.normalDrawer}>
        <MoreMenu />
      </MainDrawer>
    </>
  );
};

export default LeftMenu;
