import styles from '@styles/Header/user-avatar.module.scss';
import { Avatar, Badge, Dropdown, Grid, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentUser, useFilesUrlWithToken } from 'services/hooks';
import { usePlanOfUser } from 'services/hooks/queries/usePlanOfUser';

const UserAvatar: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isSubscribed } = usePlanOfUser();
  const f = (id: string): string => formatMessage({ id });
  const { xl } = Grid.useBreakpoint();
  const { data: userData } = useCurrentUser();

  const userInitials = useMemo(() => {
    const nameFirstChar = userData?.authUser?.name?.substring(0, 1).toLocaleUpperCase();
    const surnameFirstChar = userData?.authUser?.surname?.substring(0, 1).toLocaleUpperCase();

    if (nameFirstChar && surnameFirstChar) {
      return nameFirstChar + surnameFirstChar;
    }

    return '';
  }, [userData?.authUser?.name, userData?.authUser?.surname]);

  const { fileUrlWithToken } = useFilesUrlWithToken();

  const profilePictureUrl = useMemo(
    () => fileUrlWithToken(userData?.authUser?.image?.thumbnails?.sm),
    [userData?.authUser?.image?.thumbnails?.sm, fileUrlWithToken],
  );

  if (!xl) {
    return (
      <div className={styles.container}>
        <Badge
          className="gold-badge"
          count={isSubscribed ? <img alt="storage_img" src="/images/gold-user-icon.png" /> : 0}
          offset={[-2, 25]}
        >
          <Avatar src={profilePictureUrl} size={32} className={styles.container__avatar}>
            {userInitials}
          </Avatar>
        </Badge>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Dropdown
        overlay={<div />}
        trigger={['click']}
        className={styles.container}
        overlayClassName={styles.user_nav__dropdown}
        overlayStyle={{ minWidth: '200px' }}
        arrow={{ pointAtCenter: true }}
      >
        <Tooltip
          title={isSubscribed && f('header.menu.right.user_avatar.gold')}
          placement="bottomLeft"
          color="#09008A"
          key="#09008A"
          overlayClassName={styles.tooltip}
        >
          <Badge
            className="gold-badge"
            count={isSubscribed ? <img alt="storage_img" src="/images/gold-user-icon.png" /> : 0}
            offset={[-2, 25]}
          >
            <Avatar src={profilePictureUrl} size={32} className={styles.container__avatar}>
              {userInitials}
            </Avatar>
          </Badge>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

export default UserAvatar;
